import { isValid } from './common/helpers/isValid';
export const upperCaseFirstLetter = (string: string) => {
	if (!string) {
		return;
	}
	return (
		string.slice(0, 1).toUpperCase() +
		string.slice(1, string.length).toLowerCase()
	);
};

export const isJson = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const isValidUrl = (value: any): boolean => {
	return (
		isValid(value) &&
		/^(https:\/\/)([a-z]+\.)?[a-z0-9\-]+(\.[a-z]{2,})(\/[^ "]+)?(\?.*)?(\/)?$/i.test(value)
	);
};

export const getCroppedImg = async (
	imageSrc: string,
	cropAreaPixels: { x: number; y: number; width: number; height: number }
  ): Promise<Blob> => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
  
	if (!ctx) {
	  throw new Error('Could not get canvas context');
	}
  
	canvas.width = cropAreaPixels.width;
	canvas.height = cropAreaPixels.height;
  
	ctx.fillStyle = 'white';
	ctx.fillRect(0, 0, canvas.width, canvas.height);
  
	ctx.drawImage(
	  image,
	  cropAreaPixels.x,
	  cropAreaPixels.y,
	  cropAreaPixels.width,
	  cropAreaPixels.height,
	  0,
	  0,
	  cropAreaPixels.width,
	  cropAreaPixels.height
	);
  
	return new Promise((resolve, reject) => {
	  canvas.toBlob((blob) => {
		if (!blob) {
		  reject(new Error('Canvas is empty'));
		  return;
		}
		resolve(blob);
	  }, 'image/jpeg');
	});
  };
  
  const createImage = (url: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
	  const img = new Image();
	  img.crossOrigin = 'anonymous'; 
	  img.src = url;
	  img.onload = () => resolve(img);
	  img.onerror = (error) => reject(error);
	});
  